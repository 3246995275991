import _http from "http";
var exports = {};

/**
 * Module dependencies.
 * @private
 */
var http = _http;
/**
 * Module exports.
 * @public
 */

exports = getCurrentNodeMethods() || getBasicNodeMethods();
/**
 * Get the current Node.js methods.
 * @private
 */

function getCurrentNodeMethods() {
  return http.METHODS && http.METHODS.map(function lowerCaseMethod(method) {
    return method.toLowerCase();
  });
}
/**
 * Get the "basic" Node.js methods, a snapshot from Node.js 0.10.
 * @private
 */


function getBasicNodeMethods() {
  return ["get", "post", "put", "head", "delete", "options", "trace", "copy", "lock", "mkcol", "move", "purge", "propfind", "proppatch", "unlock", "report", "mkactivity", "checkout", "merge", "m-search", "notify", "subscribe", "unsubscribe", "patch", "search", "connect"];
}

export default exports;